<div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased " [class.comprimida]="toggleService.comprimida">
    <!-- sidebar -->
    <app-sidebar [isMobile]="isMobile"></app-sidebar>
    <!-- fin sidebar -->
    <div class="fixed right-0 max-w-full h-screen overflow-auto mainContenedor">
        <!-- header -->
        <app-header></app-header>
        <!-- fin header -->
        <div class="px-3 md:px-6">
            <!-- <app-breadcrumbs class="float-right"></app-breadcrumbs> -->
            <router-outlet></router-outlet>
        </div>


    </div>
</div>