import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss']
})
export class SlidersComponent implements OnInit {

  @Input() images:any[]
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  // default
  public swiperDefaultConfig: SwiperConfigInterface = {};
  // coverflow effect
  public swiperAutoplayConfig: SwiperConfigInterface = {
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: {
      delay: 5500,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  public swiperThumbsConfig: SwiperConfigInterface = {
    spaceBetween: 30,
    centeredSlides: true,
    slideToClickedSlide: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    thumbs: {
      swiper: {
        el: '.gallery-thumbs',
        slidesPerView: 5,
      },
      multipleActiveThumbs: true
    },
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.directiveRef){
      this.directiveRef.update()
    }
  }

}
