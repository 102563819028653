import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'src/app/service/alerts/alerts.service';
import { StoresService } from 'src/app/service/stores/stores.service';

@Component({
  selector: 'app-list-stores',
  templateUrl: './list-stores.component.html',
  styleUrls: ['./list-stores.component.scss']
})
export class ListStoresComponent implements OnInit {

  @Output() filterStore: EventEmitter<any> = new EventEmitter();
  stores: any[] = [{name: 'Todas', id: 'all'}];
  currentFilter: any = 'all';

  constructor(
    private storeService : StoresService,
    private alerts: AlertsService,
    private translate: TranslateService
  ) { this.getAllStores()

  }

  ngOnInit(): void {
    this.change(this.stores[0])
  }

  getAllStores(){
    this.storeService.getListSelect().then(res => this.stores = [...this.stores, ...res.response])
    .catch(err => this.alerts.alertError(this.translate.instant('reservation.alerts.error')))
  }

  change(store){
    this.filterStore.emit({store: store.id})
  }

}
