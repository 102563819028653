import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HomeService } from 'src/app/service/home/home.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styles: [
  ]
})
export class CardsComponent implements OnInit {
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  @Input() product:any = {}
  @Input() image:any = {}
  @Input() idx:number
  DEFAULT_PHOTO: string = "https://puu.sh/ImNRs/0ab1b352dc.png";

  ObjectKeys = Object.keys
  constructor(
    private homeservice: HomeService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
  }

  sendEvent(){
    // let checkKeysProd = Object.keys(this.product)
    let checkKeysImg = Object.keys(this.image)

    if(!this.product || Object.keys(this.product).length > 0){
      this.clickEvent.emit({event: 'click'})
      this.homeservice.setProductSelected(this.idx)
    }

    if(checkKeysImg.length > 0){
      this.clickEvent.emit({event: 'images'})
      this.idx === 0 && this.homeservice.setFirstImage(true)
      this.homeservice.img(this.image.url)
    }

  }

}
