import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private id_order: BehaviorSubject<any> = new BehaviorSubject({});


  constructor(private httpService: HttpService) { }

  setOrder(order){
    this.id_order.next(order)
  }

  getOrder(){
    return this.id_order
  }

  getQuantityOrders(){
    // let params: HttpParams = new HttpParams();
    // params = params.append('from', '');
    // params = params.append('until', '');
    // return this.httpService.get(`admin/statistics/orders/totals-count`,params)
    return this.httpService.get(`admin/statistics/orders/totals-count`)
  }

  getListPage(page: number = 1, type: string = "all", status: string = "all", search: string = "",paginate:number=10) : Promise<any> {
    return this.httpService.get(`admin/orders/list`, {page, status, type, search,paginate})
  }

  nofityWithdraw(id: string, nota: string) : Promise<any> {
    return this.httpService.put(`admin/orders/${id}/withdraw`, {nota: nota});
  }


  // las notify las pone retiradas
  nofityOrderPlus(id: string, nota: string,tracking:string) : Promise<any> {
    return this.httpService.put(`admin/orders/${id}/withdraw`, {nota: nota,tracking_id:tracking});
  }


  // las notify las pone en envio
  nofityOrder(id: string, nota: string) : Promise<any> {
    return this.httpService.put2(`admin/orders/${id}/notify`, {nota: nota});
  }

  getOrderByUoc(uoc: string) : Promise<any> {
    return this.httpService.get(`admin/orders/${uoc}/detail`);
  }

  getOrderWholesale(uoc: string) : Promise<any> {
    return this.httpService.get(`admin/wholesales/orders/${uoc}/detail`);
  }

  approvedOrder(id:string): Promise<any> {
    return this.httpService.put(`admin/orders/${id}/approved`)
  }


  // aprobar manual
  approvedManual(data): Promise<any> {
    return this.httpService.post(`admin/support/payment/approvemanual`, data)
  }
  // aprobar manual fin

}
