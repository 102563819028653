import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryServiceTurnos {
  activatedReload: BehaviorSubject<boolean> = new BehaviorSubject(false)
  categoryEdit: BehaviorSubject<any> = new BehaviorSubject({})
  categoryDetail: BehaviorSubject<boolean> = new BehaviorSubject(false)
  addServices: BehaviorSubject<boolean> = new BehaviorSubject(false)
  id_ctg: BehaviorSubject<String> = new BehaviorSubject('')

  constructor(private httpService: HttpService) { }

  setid(id:string){
    this.id_ctg.next(id)
  }

  getId(): Observable<String>{
    return this.id_ctg
  }

  setServices(value:boolean){
    this.addServices.next(value)
  }

  getServices(): Observable<boolean>{
    return this.addServices
  }

  setDetail(value:boolean){
    this.categoryDetail.next(value)
  }

  getDetail(): Observable<any>{
    return this.categoryDetail
  }

  setCategory(category: Object){
    this.categoryEdit.next(category)
  }

  getCateg(): Observable<any>{
    return this.categoryEdit
  }

  setReload(value:boolean){
    this.activatedReload.next(value)
  }

  getReload():Observable<any>{
    return this.activatedReload
  }

  getList(page: number = 1, search: any = ""){
    return this.httpService.get("admin/services/category/list", {page,search})
  }


  createCategory(data:object){
    return this.httpService.post("admin/services/category", data)
  }

  updateCategory(id:string,data:object){
    return this.httpService.put(`admin/services/category/${id}`, data)
  }

  assignService(id_category:string,data){
    return this.httpService.post(`admin/services/category/${id_category}/addService`, data)
  }

  trash(id:string,data:object){
    return this.httpService.put(`admin/services/category/${id}/trash`,data)
  }

}
