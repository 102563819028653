import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) {}

  doLogin(data: object) {
    return this.httpService.loginPostWithoutToken('authenticate', data);
  }

  doLogout(): void {
    this.storageService.clear();
  }

  isAuthenticated(): boolean {
    return this.storageService.isAuthenticated();
  }

  getMyRol(): Promise<any> {
    return this.httpService.post('admin/users/getRole');
  }
}
