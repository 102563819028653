import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesTurnosService {
  personal: BehaviorSubject<any> = new BehaviorSubject({})
  service: BehaviorSubject<any> = new BehaviorSubject({})
  reload: BehaviorSubject<boolean> = new BehaviorSubject(false)
  detail: BehaviorSubject<boolean> = new BehaviorSubject(false)
  edit: BehaviorSubject<boolean> = new BehaviorSubject(false)
  constructor(private httpService: HttpService) { }

  setEdit(value:boolean){
    this.edit.next(value)
  }

  getEdit(): Observable<boolean>{
    let edit = JSON.parse(localStorage.getItem('edit'))
    edit && this.edit.next(edit)
    return this.edit
  }

  setReload(value:boolean){
    this.reload.next(value)
  }

  getReload():Observable<any>{
    return this.reload
  }

  setPersonal(personal){
    this.personal.next(personal)
  }

  getPersonal(): Observable<any>{
    return this.personal
  }

  setDetail(value:boolean){
    this.detail.next(value)
  }

  setService(service){
    this.service.next(service)
  }

  getService(): Observable<any>{
    let service = JSON.parse(localStorage.getItem('service'))
    if(Object.keys(service).length > 0){
      this.service.next(service)
      return this.service
    }
    return this.service
  }

  getDetail(): Observable<any>{
    let detail = JSON.parse(localStorage.getItem('detail'))
    detail && this.detail.next(detail)
    return this.detail
  }

  listServicesSstaff(page: number = 1, search: any = ""){
    return this.httpService.get("admin/services/listservicestaff", {page, search})
  }

  getStaff(page?,search?){
    return this.httpService.get("admin/staff/list", {page, search})
  }

  trashStaff(id:string,data:object){
    return this.httpService.put(`admin/staff/${id}/trash`,data)
  }

  postStaff(data){
    return this.httpService.post("admin/staff", data)
  }

  updateStaff(id:string, data){
    return this.httpService.put(`admin/staff/${id}`, data)
  }

  trash(id:string,data){
    return this.httpService.put(`admin/services/${id}/trash`, data)
  }

  saveService(data:object){
    return this.httpService.post("admin/services", data)
  }

  assignStaff(service:string, data:object){
    return this.httpService.post(`admin/services/${service}/addstaff`, data)
  }

  assignCtgToService(id_category:string,data){
    return this.httpService.post(`admin/services/category/${id_category}/addService`, data)
  }

  updateService(id:string, data:object){
    return this.httpService.put(`admin/services/${id}`, data)
  }

}
