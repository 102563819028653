import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'src/app/service/alerts/alerts.service';
import { CategoryServiceTurnos } from 'src/app/service/category/category.service';
import { ServicesTurnosService } from 'src/app/service/servicesTurnos/services-turnos.service';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styles: [
    `
      input[type="color"] {
        width: 30%;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
      }

      input[type="color"]::-webkit-color-swatch {
        border: none;
        border-radius: 50%;
        padding: 0;
      }

      input[type="color"]::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 50%;
        padding: 0;
      }
    `
  ]
})
export class CreateCategoryComponent implements OnInit, OnDestroy {
  edit:boolean = false
  detail:boolean = false
  addServices:boolean = false
  servicesList:any[] = []
  @ViewChild('modalComponent') modal:
  | ModalComponent<CreateCategoryComponent>
  | undefined;

  categoryForm = {
    name: '',
    description: '',
    color: ''
  }
  selectedServices:any[] = []
  idCtg:string = ''
  id_store:string = ''
  constructor(
    private services: ServicesTurnosService,
    private spinner: SpinnerService,
    private categoryService: CategoryServiceTurnos,
    private alerts: AlertsService,
    private translate: TranslateService
  ) {
    const { id } = JSON.parse(localStorage.getItem("store")) || "";
    this.id_store = id
    this.getCategory()
    this.listenDetail()
    this.listenServices()
    this.getId()
  }

  getId(){
    this.categoryService.getId().subscribe((id:string) => id !== '' && (this.idCtg = id) )
  }

  listenServices(){
    this.categoryService.getServices().subscribe((value:boolean) => value && (this.addServices = true) )
  }

  listenDetail(){
    this.categoryService.getDetail().subscribe((value:boolean) => value && (this.detail = true))
  }

  getCategory(){
    this.categoryService.getCateg().subscribe((ctg:Object | any) =>  {
      Object.keys(ctg).length > 0 && (this.categoryForm = ctg)
      Object.keys(ctg).length > 0 && (this.edit = true)
      Object.keys(ctg).length > 0 && (this.categoryForm['id_category'] = ctg.id)
     })
  }


  ngOnInit(): void {
    this.getServices(1)
  }

  close(){
    this.modal.close()
  }

  getServices(page:number){
    this.services.listServicesSstaff(page)
    .then(res => {
      console.log('servicios',res)
      this.servicesList = res.response.data
    })
    .catch(err => {
      console.log(err)
    })
  }

  selectedService(id:string, idx:number){
    !this.selectedServices.includes(id) ? this.selectedServices.push(id) : this.selectedServices.splice(idx, 1)
    console.log(this.selectedServices)
  }

  save(){
    this.categoryForm['id_store'] = this.id_store
    this.spinner.show()
    this.categoryService.createCategory(this.categoryForm)
    .then(res => {
      console.log(res)
      this.spinner.hide()
      this.idCtg = res.data.id
      this.addServicesCtg()
    })
    .catch(err => {
      console.log(err)
      this.spinner.hide()
    })
  }

  update(){
    this.spinner.show()
    this.categoryService.updateCategory(this.categoryForm['id_category'], this.categoryForm)
    .then(res => {
      console.log(res)
      this.spinner.hide()
      this.alerts.alertSuccess(this.translate.instant('exito'))
      this.modal.close()
      this.categoryService.setReload(true)
    })
    .catch(err => {
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
      this.spinner.hide()
    })
  }

  async addServicesCtg(){
    this.spinner.show()
    const promises = this.selectedServices.map(async (id:string) => {
      let data = {
        id_service: id
      }
      const response = await this.categoryService.assignService(this.idCtg, data)
      return response
    })

    try {
      await Promise.all(promises)
      this.spinner.hide()
      this.alerts.alertSuccess(this.translate.instant('exito'))
      this.modal.close()
      this.categoryService.setReload(true)
    } catch (error) {
      this.spinner.hide()
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
    }

  }

  ngOnDestroy(){
    this.categoryService.setCategory({})
    this.categoryService.setDetail(false)
    this.categoryService.setServices(false)
    this.categoryService.setid('')
    this.edit = false
    this.detail = false
    this.addServices = false
    this.idCtg = ''
  }

}
