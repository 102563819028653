import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CalendarService } from 'src/app/service/calendar/calendar.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';
import * as moment from 'moment';
import { ServicesTurnosService } from 'src/app/service/servicesTurnos/services-turnos.service';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'src/app/service/alerts/alerts.service';

@Component({
  selector: 'app-detail-reserva',
  templateUrl: './detail-reserva.component.html',
  styles: [
  ]
})
export class DetailReservaComponent implements OnInit, OnDestroy {

  @ViewChild('modalComponent') modal:
  | ModalComponent<DetailReservaComponent>
  | undefined;

  staff:any[] = []
  services:any[] = []
  reservation:any = {
    start: null
  }
  role:string = ''
  constructor(
    private calendarService: CalendarService,
    private serviceTurnos: ServicesTurnosService,
    private cd: ChangeDetectorRef,
    private spinner: SpinnerService,
    private translate: TranslateService,
    private alerts: AlertsService
  ) {
    this.role = JSON.parse(localStorage.getItem('role'));
    this.listenReservation()
   }

  ngOnInit(): void {
    this.getServices()
  }


  getServices(){
    this.serviceTurnos.listServicesSstaff()
    .then(res => {
      console.log(res)
      this.services = res.response.data;
      this.reservation.id_service = this.services[0].id
      this.getChanges(this.reservation.id_service)
      this.cd.detectChanges();
    })
    .catch(err => console.log(err))
  }

  getChanges(data){
    this.reservation.id_service = data
    let service = this.services.find(s => s.id === data)
    this.staff = service.staffs.map(staff => staff.staff)
    this.reservation.id_staff = this.staff[0].id
  }


  listenReservation(){
    this.calendarService.getReserva()
    .subscribe(reserva => {

      if(Object.keys(reserva).length > 0){
        console.log(reserva)
        this.reservation = reserva
      }

    })
  }

  close(){
    this.modal.close()
  }

  update(){
    const format1 = "YYYY-MM-DD HH:mm:ss"
    let data = {
      day: new Date(this.reservation.start.getFullYear(), this.reservation.start.getMonth() + 1, 0).toISOString().slice(0,10),
      id_store: JSON.parse(localStorage.getItem('store')).id,
      start: moment(this.reservation.start).format(format1),
      end: moment(this.reservation.end).format(format1),
      id_customer: this.reservation.customer.id,
      id_service: this.reservation.id_service,
      id_staff: this.reservation.id_staff
    }

    this.spinner.show()
    this.calendarService.update(this.reservation.id, data)
    .then(res => {
      this.alerts.alertSuccess(this.translate.instant('exito'))
      this.spinner.hide()
      this.close()
    })
    .catch(err => {
      this.spinner.hide()
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
    })
  }

  ngOnDestroy(){
    this.calendarService.setReservation({})
  }

}
