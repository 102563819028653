import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigGeneralService {

  private reloadPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showStore: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private config: BehaviorSubject<any> = new BehaviorSubject({});
  private id_config: BehaviorSubject<any> = new BehaviorSubject(null);
  private type: BehaviorSubject<string> = new BehaviorSubject('');
  store: BehaviorSubject<string> = new BehaviorSubject('');
  private configStore : BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(private httpService: HttpService) { }

  setType(value:string){
    this.type.next(value)
  }

  getType(): Observable<string>{
    return this.type
  }

  setConfig(data){
    this.config.next(data)
  }

  getConfig(): Observable<any>{
    return this.config
  }

  setShowStore(value:boolean){
    this.showStore.next(value)
  }

  getShowStore(): Observable<boolean>{
    return this.showStore
  }

  setReload(value:boolean){
    this.reloadPage.next(value)
  }

  getIdConfiguration(): Observable<any>{
    return this.id_config
  }

  setIdConfiguration(value){
    this.id_config.next(value)
  }

  getReload(): Observable<boolean>{
    return this.reloadPage
  }

  create(data:object): Promise<any>{
    return this.httpService.post('admin/configurationsaround/create', data)
  }

  update(data:object): Promise<any>{
    return this.httpService.post('admin/configurationsaround/update', data)
  }

  getAllConfig(){
    let config = JSON.parse(localStorage.getItem('config'))
    this.configStore.next(config)
    return this.configStore
  }

  getListPublic(){
    this.httpService.get('configurationsaround/get')
    .then(res => {
      localStorage.setItem('config', JSON.stringify(res.response))
      this.configStore.next(res.response)
    })
  }

  getList():Promise<any>{
    return this.httpService.get('admin/configurationsaround/get')
  }

  delete(data:object): Promise<any>{
    return this.httpService.delete('admin/configurationsaround/delete', data)
  }

  applyRuleToStore(data): Promise<any>{
    return this.httpService.post('admin/configurationsaround/valueconfigstore', data)
  }

  getListByStore(store:string): Promise<any>{
    let params : HttpParams = new HttpParams()
    .append('id_store', String(store));
    return this.httpService.get('admin/configurationsaround/forstore', params)
  }
}
