import { ChangeDetectorRef, Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfigGeneralService } from './service/configGeneral/config-general.service';
import { HomeService } from './service/home/home.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  spinner:string = ''
  private subscriptions: Array<Subscription> = [];
  language:any = null
  constructor(
    private configGeneral: ConfigGeneralService,
    private homeSrv: HomeService,
    private cd: ChangeDetectorRef,
    ){
    this.getConfigStore()
    this.homeSrv.changeLng()
    this.getLang()
  }

  title = 'backoffice';

  ngOnInit(){
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
  getConfigStore(){
    this.subscriptions.push(
      this.configGeneral.getAllConfig()
      .subscribe((config:any[]) => config.map(({name, value}) => name === 'spinner' && (this.spinner = value)))
    )
  }

  getLang(){
    // console.log('getLang')
    this.homeSrv.getLanguage()
    .then((res:any) => {
    // console.log('setItem',res.data)
      localStorage.setItem('language', JSON.stringify(res.data))
    })
    .catch(err => {
      console.log(err)
    })
  }

}
