import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  private token : string = '';
  private tokenExtern : string = '';
  constructor(
    private storageService : StorageService
  ) {
    this.storageService.getToken()
    .subscribe((token : any) => {
      if(token != null && token != undefined) {
        this.token = token;
      }
    },(error: any) : any => {
      console.log(error);
    });

    this.storageService.getTokenExtern()
    .subscribe((tokenExtern : any) => {
      if(tokenExtern != null && tokenExtern != undefined && tokenExtern != '') {
        this.tokenExtern = tokenExtern;
        // console.log('ext',this.tokenExtern)
      }
    },(error: any) : any => {
      console.log(error);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.includes(".json") || req.url.includes("admin/files") ){
      return next.handle(req)
    }

    let headers: any = {'Content-Type': `application/json` }

    if(this.token && this.tokenExtern == '') {
      headers.Authorization = `Bearer ${this.token}`
    }
  
    let request:any

    if(req.method.toLowerCase() === "post"){
      if(req.body instanceof FormData){
        request = req.clone({
          body: req.body.append("id_client", environment.CLIENT)
        })
      }else{
        this.tokenExtern ? 
        request = req.clone({
          setHeaders:headers,
          params: req.params.set("id_client", environment.CLIENT)
          .set('token',this.tokenExtern)
        })
        : request = req.clone({
          setHeaders:headers,
          params: req.params.set("id_client", environment.CLIENT)
        })
      }
    }
    else if(req.method.toLowerCase() === "get" || req.method.toLowerCase() === "put" || req.method.toLowerCase() === "delete"){
    

      this.tokenExtern ? 
      request = req.clone({
        setHeaders:headers,
        params: req.params.set("id_client", environment.CLIENT)
        .set('token',this.tokenExtern)
      })
      :  request = req.clone({
        setHeaders:headers,
        params: req.params.set("id_client", environment.CLIENT)
      })
    }
    return next.handle(request)
  }



}
