<div class="flex flex-col" [formGroup]="schedules">
    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">{{'horarios.lunes' | translate}} :</label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                          let service of schedules
                            .get('mondayHours')
                            .get('configHorarios').value;
                          let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'monday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="mondayHours" class="flex gap-1 md:gap-3 ">
                <fieldset class="input-clock">
                    <input id="monday_opening" type="time" class="input-text" placeholder="" formControlName="mondayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="monday_closing" type="time" class="input-text" placeholder="" formControlName="mondayHoursClose" />
                </fieldset>

                <fieldset class="input-clock">
                    <button (click)="add('mondayHours')" *ngIf="this.disabled" class="btn btn-secondary btn-sm mx-auto md:mx-auto md:ml-2">
                                  <!-- {{'reservas.agregarHorario' | translate}} -->
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">
                      {{'horarios.martes' | translate}} :
                    </label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                            let service of schedules
                              .get('tuesdayHours')
                              .get('configHorarios').value;
                            let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'tuesday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="tuesdayHours" class="flex gap-1 md:gap-3">
                <fieldset class="input-clock">
                    <input id="tuesday_closing" type="time" class="input-text" placeholder="" formControlName="tuesdayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="tuesday_closing" type="time" class="input-text" placeholder="" formControlName="tuesdayHoursClose" />
                </fieldset>
                <fieldset class="input-clock">
                    <button (click)="add('tuesdayHours')" *ngIf="this.disabled" class="btn btn-secondary btn-sm mx-auto md:mx-auto md:ml-2">
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">
                      {{'horarios.miercoles' | translate}} :
                    </label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                            let service of schedules
                              .get('wednesdayHours')
                              .get('configHorarios').value;
                            let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'wednesday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="wednesdayHours" class="flex gap-1 md:gap-3">
                <fieldset class="input-clock">
                    <input id="wednesday_opening" type="time" class="input-text" placeholder="" formControlName="wednesdayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="wednesday_closing" type="time" class="input-text" placeholder="" formControlName="wednesdayHoursClose" />
                </fieldset>
                <fieldset class="input-clock">
                    <button *ngIf="this.disabled" (click)="add('wednesdayHours')" class="btn btn-secondary btn-sm mx-auto md:ml-2">
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">
                      {{'horarios.jueves' | translate}} :
                    </label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                            let service of schedules
                              .get('thursdayHours')
                              .get('configHorarios').value;
                            let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'thursday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="thursdayHours" class="flex gap-1 md:gap-3">
                <fieldset class="input-clock">
                    <input id="thursday_opening" type="time" class="input-text" placeholder="" formControlName="thursdayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="thursday_closing" type="time" class="input-text" placeholder="" formControlName="thursdayHoursClose" />
                </fieldset>
                <fieldset class="input-clock">
                    <button *ngIf="this.disabled" (click)="add('thursdayHours')" class="btn btn-secondary btn-sm mx-auto md:ml-2">
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>
        </div>
    </div>
    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">
                      {{'horarios.viernes' | translate}} :
                    </label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                            let service of schedules
                              .get('fridayHours')
                              .get('configHorarios').value;
                            let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'friday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="fridayHours" class="flex gap-1 md:gap-3">
                <fieldset class="input-clock">
                    <input id="friday_opening" type="time" class="input-text" placeholder="" formControlName="fridayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="friday_closing" type="time" class="input-text" placeholder="" formControlName="fridayHoursClose" />
                </fieldset>
                <fieldset class="input-clock">
                    <button *ngIf="this.disabled" (click)="add('fridayHours')" class="btn btn-secondary btn-sm mx-auto md:ml-2">
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>

        </div>
    </div>
    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">
                      {{'horarios.sabado' | translate}} :
                    </label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                            let service of schedules
                              .get('saturdayHours')
                              .get('configHorarios').value;
                            let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'saturday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="saturdayHours" class="flex gap-1 md:gap-3">
                <fieldset class="input-clock">
                    <input id="saturday_opening" type="time" class="input-text" placeholder="" formControlName="saturdayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="saturday_closing" type="time" class="input-text" placeholder="" formControlName="saturdayHoursClose" />
                </fieldset>
                <fieldset class="input-clock">
                    <button *ngIf="this.disabled" (click)="add('saturdayHours')" class="btn btn-secondary btn-sm mx-auto md:ml-2">
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>
        </div>
    </div>
    <div class="border-b sm:border-none mb-2 sm:mb-0">
        <div class="flex flex-col">
            <label class="">
                      {{'horarios.domingo' | translate}} :
                    </label>
            <ul class="my-2 flex flex-wrap">
                <ng-container *ngFor="
                            let service of schedules
                              .get('sundayHours')
                              .get('configHorarios').value;
                            let i = index
                        ">
                    <li class="text-center lg:text-left mr-4 whitespace-nowrap">
                        {{ service.open }} - {{ service.close }}
                        <span (click)="removeHorario(i, 'sunday')" class="cursor-pointer"><span class="icon-trash"></span></span>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="flex items-center mb-3">
            <div formGroupName="sundayHours" class="flex gap-1 md:gap-3">
                <fieldset class="input-clock">
                    <input id="sunday_opening" type="time" class="input-text" placeholder="" formControlName="sundayHoursOpen" />
                </fieldset>
                <span class="hidden lg:inline-block md:px-2 m-auto">-</span>
                <fieldset class="input-clock">
                    <input id="sunday_closing" type="time" class="input-text" placeholder="" formControlName="sundayHoursClose" />
                </fieldset>
                <fieldset class="input-clock">
                    <button *ngIf="this.disabled" (click)="add('sundayHours')" class="btn btn-secondary btn-sm mx-auto md:ml-2">
                                  <span class="icon-add text-xl"></span>
                                </button>
                </fieldset>
            </div>
        </div>
    </div>
</div>