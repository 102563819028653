<app-modal #modalComponent>


    <ng-container *ngIf="step1">

        <div class="flex flex-col lg:flex-row justify-between xl:w-screen xl:max-w-screen-lg gap-6">
            <div class="flex-1">
                <app-calendar (emitDates)="getEvent($event)"></app-calendar>
            </div>

            <div class="lg:w-80">
                <select (change)="getStaff($event.target['value'])" class="bg-principal-claro dark:bg-dark-input hover:bg-principal hover:text-white flex justify-center py-2 w-full px-5 cursor-pointer" style="appearance: none;" name="" id="">
                    <option *ngFor="let staff of service.service.staffs" class="text-center mx-auto" [value]="staff.staff.id"> {{staff.staff.name}} {{staff.staff.lastname}} </option>
                </select>

                <!-- seleccion de horarios -->
                <div>
                    <h6 class="my-5 font-normal">Seleccionar los horarios:</h6>
                    <div>
                        <h6 class="mb-2 mt-5">{{'turnos.manana' | translate}}:</h6>
                        <div *ngIf="dataMorning.length > 0" class="grid grid-cols-3 gap-2">
                            <button *ngFor="let hours of dataMorning; let i = index" (click)="hoursSelected = hours;
                              clicked = hours" [ngClass]="clicked === hours ? 'hora-si' : 'hora-no'" class="rounded border border-gris-claro dark:border-dark-fondo shadow p-1 text-sm text-gris-base hover:text-negro-principal hover:bg-principal-claro dark:hover:bg-dark-input dark:hover:text-gris-base focus:bg-principal focus:outline-none">
                              {{hours.start}} - {{hours.end}}
                            </button>
                        </div>
                    </div>
                    <div>
                        <h6 class="mb-2 mt-5">{{'turnos.tarde' | translate}}:</h6>
                        <div *ngIf="dataAfternoon.length > 0" class="grid grid-cols-3 gap-2">
                            <button *ngFor="let hours of dataAfternoon; let i = index" (click)="hoursSelected = hours;
                                clicked = hours" [ngClass]="clicked === hours ? 'hora-si' : 'hora-no'" class="rounded border border-gris-claro dark:border-dark-fondo shadow p-1 text-sm text-gris-base hover:text-negro-principal hover:bg-principal-claro dark:hover:bg-dark-input dark:hover:text-gris-base focus:bg-principal focus:outline-none">
                                {{hours.start}} - {{hours.end}}
                            </button>
                        </div>
                    </div>
                    <div>
                        <h6 class="mb-2 mt-5">{{'turnos.noche' | translate}}:</h6>
                        <div *ngIf="dataNight.length > 0" class="grid grid-cols-3 gap-2">
                            <button *ngFor="let hours of dataNight; let i = index" (click)="hoursSelected = hours;
                              clicked = hours" [ngClass]="clicked === hours ? 'hora-si' : 'hora-no'" class="rounded border border-gris-claro dark:border-dark-fondo shadow p-1 text-sm text-gris-base hover:text-negro-principal hover:bg-principal-claro dark:hover:bg-dark-input dark:hover:text-gris-base focus:bg-principal focus:outline-none">
                              {{hours.start}} - {{hours.end}}
                            </button>
                        </div>
                    </div>

                </div>

            </div>



        </div>

    </ng-container>

    <ng-container *ngIf="!step1">

        <form [formGroup]="Customer" class="">

            <div class="flex flex-wrap justify-between max-w-xl gap-4">

                <div class="w-full">
                    <input type="text" formControlName="name" class="input-text" placeholder="Nombre">
                </div>

                <div class="w-full">
                    <input type="text" formControlName="lastname" class="input-text" placeholder="Apellido">
                </div>


                <div class="w-full">
                    <input type="text" class="input-text" formControlName="email" placeholder="Correo">
                    <p *ngIf="email" class="text-danger"> {{'validacion.correo' | translate}} </p>
                </div>

                <div class="w-full">
                    <input type="text" class="input-text" formControlName="phone" placeholder="Telefono">
                    <p *ngIf="phone" class="text-danger"> {{'validacion.numeros' |translate }} </p>
                </div>

            </div>

        </form>

    </ng-container>

    <div class="mt-6 flex flex-col sm:flex-row sm:justify-end gap-4">
        <button (click)="close()" type="button" class="btn btn-link"> {{'buttons.cerrar' | translate}} </button>
        <button *ngIf="ObjectKeys(hoursSelected).length > 0" (click)="step1 = !step1" type="button" class="btn btn-principal"> {{step1 ? 'Siguiente' :  'Volver' }} </button>
        <button *ngIf="!step1" (click)="saveCustomer()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button>
    </div>

</app-modal>

<ng-template #toolbar>
</ng-template>