import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AlertsService } from 'src/app/service/alerts/alerts.service';

import { CalendarService } from 'src/app/service/calendar/calendar.service';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';



@Component({
  selector: 'app-create-reservation',
  templateUrl: './create-reservation.component.html',
  styles: [
    `
      .hora-no {
        
      }

    .hora-si {
        background: #1977DF;
        color: white;
        border-color:#1977DF;
    }

    `
  ]
})
export class CreateReservationComponent implements OnInit, OnDestroy {
  staffSelected:string = ''
  private $data_obs: Subscription = new Subscription()
  service:any = {}
  @ViewChild('modalComponent') modal:
  | ModalComponent<CreateReservationComponent>
  | undefined;
  step1: boolean = true

  morning = {
    start: new Date('1/1/1999 ' + '00:00'),
    end: new Date('1/1/1999 ' + '12:00'),
  }
  afternoon = {
    start: new Date('1/1/1999 ' + '12:00'),
    end: new Date('1/1/1999 ' + '18:00'),
  }
  night = {
    start: new Date('1/1/1999 ' + '18:00'),
    end: new Date('1/1/1999 ' + '23:59'),
  }
  dataMorning: any[] = []
  dataAfternoon: any[] = []
  dataNight:any[] = []
  store:string = ''
  id_service: string = ''
  day:string = ''
  hoursSelected:any = {}
  clicked:any
  Customer: FormGroup
  id_customer: string = ''
  start:string = ''
  end:string = ''
  id_service_ctg:string = ''
  ObjectKeys = Object.keys
  constructor(
    private calendarService: CalendarService,
    private spinner: SpinnerService,
    private alerts: AlertsService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.listenReservation()
    this.createForm()
  }

  ngOnInit(): void {
  }

  createForm(){
    this.Customer = this.fb.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      phone: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
    })
  }

  get email(){
    return this.Customer.get('email').invalid && this.Customer.get('email').touched
  }

  get phone(){
    return this.Customer.get('phone').invalid &&  this.Customer.get('phone').touched
  }


  listenReservation(){
    this.$data_obs.add(
      this.calendarService.getDataCtg().subscribe((data: Object) => {
        Object.keys(data).length > 0 && ( this.service = data )
        console.log(this.service)
        this.store = this.service.store
        this.staffSelected = this.service.service.staffs[0].staff.id
        this.id_service = this.service.service.id
      })
    )
  }

  ngOnDestroy(){
    this.$data_obs.unsubscribe()
  }

  close(){
    this.modal.close()
  }

  getEvent(date){
    const format1 = "YYYY-MM-DD"
    this.day = moment(date).format(format1)
    this.getListDays()
  }

  getStaff(event){
    this.staffSelected = event
    this.getListDays()
  }


  filterDays(data:[]){
    // d.start >= this.morning.start && d.end <= this.morning.start
    this.dataMorning = data.filter((d:any) => {
      let start = new Date('1/1/1999 ' + d.start);
      let end = new Date('1/1/1999 ' + d.end);

      if(start >= this.morning.start && end <= this.morning.end){
        return true
      }
    })

    this.dataAfternoon = data.filter((d:any) => {
      let start = new Date('1/1/1999 ' + d.start);
      let end = new Date('1/1/1999 ' + d.end);

      if(start >= this.afternoon.start && end <= this.afternoon.end){
        return true
      }
    })

    this.dataNight = data.filter((d:any) => {
      let start = new Date('1/1/1999 ' + d.start);
      let end = new Date('1/1/1999 ' + d.end);

      if(start >= this.night.start && end <= this.night.end){
        return true
      }
    })
    console.log(this.dataMorning)
    console.log(this.dataAfternoon)
    console.log(this.dataNight)
  }

  getListDays(){
    this.spinner.show()
    this.calendarService.listDays(this.store, this.day, this.staffSelected, this.id_service)
    .then(res => {
      console.log('==>> list days', res)
      this.filterDays(res.response)
      this.spinner.hide()
    })
    .catch(err => {
      this.spinner.hide()
      if(err.msg === 'invalid.min_day') return this.alerts.alertError(this.translate.instant('reservation.alerts.min_date'))
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
      this.spinner.hide()
    })
  }



  saveCustomer(){
    if(this.Customer.invalid) return this.alerts.alertError(this.translate.instant('validacion.campos'))

    let customer = {
      customer: this.Customer.value
    }

    this.calendarService.createCustomer(this.store, customer)
    .then(res => {
      this.id_customer = res.data.id
      this.makeReservation()
    })
    .catch(err => {
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
    })
  }

  makeReservation(){
    this.spinner.show()
    let data = {
      id_store: this.store,
      id_service: this.id_service,
      id_staff: this.staffSelected,
      id_customer: this.id_customer,
      day: this.day,
      start: this.hoursSelected.start,
      end: this.hoursSelected.end,
      id_service_category: this.service.id_ctg
    }

    this.calendarService.makeReservation(data)
    .then(res => {
      this.alerts.alertSuccess(this.translate.instant('exito'))
      this.spinner.hide()
      this.close()
      this.router.navigate(['/turnos/calendar'])
    })
    .catch(err => {
      this.spinner.hide()
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
    })

  }

}
