<div class="swiper-container relative" [swiper]="swiperAutoplayConfig">
    <div class="swiper-wrapper">
        <div *ngFor="let image of images" class="swiper-slide cursor-pointer">
            <img class="img-fluid w-full h-96 object-cover" [src]="image.url">
            <div *ngIf="image.text1 || image.text2 || image.text3" class="absolute top-0 left-0 z-0 w-full h-full px-12 flex flex-col justify-center text-left">
                <span class="md:w-1/3 text-3xl mt-4 leading-7 font-bold">{{image.text1}}</span>
                <span class="md:w-1/3 text-sm my-4 leading-4">{{image.text2}}</span>
                <span class="w-max bg-principal text-white text-center py-3 px-5 font-normal rounded-md">{{image.text3}}</span>
            </div>
        </div>
    </div>
    <!-- Add Pagination -->
    <div class="swiper-pagination"></div>
    <div class="absolute top-0 right-0 h-full z-10 flex items-center">
        <button class="swiper-button-next px-2 mr-1" aria-label="Next" tabindex="0">&#10095;</button>
    </div>
    <div class="absolute top-0 left-0 h-full z-10 flex items-center">
        <button class="swiper-button-prev px-2 ml-1" aria-label="Prev" tabindex="0">&#10094;</button>
    </div>
</div>