<app-modal #modalComponent>


    <form [formGroup]="staff">

        <div class="row">

            <div class="col-12">
                <input name="name" [readonly]="detail" formControlName="name" type="text" class="input-text" placeholder="Name">
            </div>

            <div class="col-12 my-5">
                <input name="lastname" [readonly]="detail" formControlName="lastname" type="text" class="input-text" placeholder="Lastname">
            </div>

        </div>

    </form>

    <div>
        <app-horario [horarios]="this.schedules.value" [disabled]="detail ? false : true" (event)="getHours($event)"></app-horario>
    </div>

    <div class="mt-6 flex justify-end">
        <button (click)="close()" type="button" class="btn btn-link btn-sm mr-4"> {{'buttons.cerrar' | translate}} </button>
        <button *ngIf="!detail" (click)="edit ? update() : save()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button>
    </div>
</app-modal>

<ng-template #toolbar>
</ng-template>