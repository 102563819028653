import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConfigurationService } from 'src/app/service/configuration/configuration.service';
import { OrderService } from 'src/app/service/order/order.service';

@Component({
  selector: 'app-tabs-components',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  @Input() tabsOrder: boolean = false
  @Input() showOrdersTabs: boolean = true

  @Input() tabsOrderWholesale: boolean = false
  @Input() users:boolean = false
  @Input() support:boolean = false
  @Input() campaign:boolean = false
  @Input() clone:boolean = false
  @Input() typeCampaign:string = ''

  all = false
  children = false
  inactivo = false
  activo = true


  path:string = ''
  productosPath:string = 'productos' 

  role:string = ''


  tabs:any =  { id: 0, label: 'Pendientes por Pago', status: 'pending', type: '' }
  tabsWholesales :any = { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' }
  tabsOrdersUsersMain: any = { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' }
  tabsSupportMain:any = { id: 0, label: 'Pendientes', status: 'pending', type: '' }
  tabsCampaingMain:any = { id: 0, label: 'campaignData.uploadFile', status: 'file' }

  tabsOrders:any[] =
  [
    { id: 0, label: 'Pendientes', status: 'pending', type: '', cantidad: 0 },
    { id: 1, label: 'Pendientes por Envio', status: 'approved', type: 'shipping', cantidad: 0 },
    { id: 2, label: 'Pendientes por Retiro', status: 'approved', type: 'retired', cantidad: 0 },
    { id: 3, label: 'Retiradas', status: 'retired', type: '', cantidad: 0 },
    { id: 4, label: 'Enviadas', status: 'shipping', type: '', cantidad: 0 },
    { id: 5, label: 'orders.all', status: 'all', type: '', cantidad: 0 },
  ]

  tabsOrdersWholesale:any[] =
  [
    { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' },
    { id: 1, label: 'wholesales.orders.aprobadas', status: 'approved' },
    { id: 2, label: 'wholesales.orders.enviadas', status: 'shipping' },
  ]

  tabsOrdersUsers:any[] =
  [
    { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' },
    { id: 1, label: 'products.actives', status: 'active' },
    { id: 2, label: 'products.inactives', status: 'inactive' },
  ]

  tabsSupport:any[] = [

    { id: 0, label: 'Pendientes', status: 'pending', type: '' },
    { id: 1, label: 'Rechazadas', status: 'reject' },
    { id: 2, label: 'Fallidas', status: 'fail' },
    { id: 3, label: 'Canceladas', status: 'cancel' },

  ]

  tabsCampaing:any[] = [

    { id: 0, label: 'campaignData.uploadFile', status: 'file' },
    { id: 1, label: 'campaignData.contactos', status: 'contacts' },
    { id: 2, label: 'campaignData.contactProd', status: 'products' },

  ]

  constructor(
    private configurationService: ConfigurationService,
    private orders: OrderService,
  ) { 
    localStorage.setItem('loadOrders', '0')
    this.role = JSON.parse(localStorage.getItem('role'))
    this.path = window.location.href.split('/').pop()
  }

  ngOnInit(): void {
    if(this.clone && this.typeCampaign !== ''){
      this.moveTab(this.typeCampaign)
    }
   this.role === 'store' &&  this.setShowTabs()
   this.getOrders()
  }



  setShowTabs(){
    this.configurationService.getListByStore(JSON.parse(localStorage.getItem('store')).id)
    .then(res => {
      res.response.map(item =>{
        if(item.name == 'showorderstabs'){
          this.showOrdersTabs = item.value.toLowerCase() === 'true'
        }
        
      })
    })
    .catch(err => {
      console.log(err)
    })

  }

  getOrders(){
      localStorage.setItem('loadOrders', '1')
      this.orders.getQuantityOrders()
      .then(res => {
        res.response.map(order=>{
            switch(order.status){
              case 'pending':
                  this.tabsOrders[0].cantidad = order.total
              break;
              case 'pending_delivered': 
              this.tabsOrders[1].cantidad = order.total
              break;
              case 'pending_retired': 
              this.tabsOrders[2].cantidad = order.total
              break;
              case 'retired': 
              this.tabsOrders[3].cantidad = order.total
              break;
              case 'delivered': 
              this.tabsOrders[4].cantidad = order.total
              break;
              case 'all': 
              this.tabsOrders[5].cantidad = order.total
              break;
            }
        })
      })
      .catch(err =>   console.log(err))
    // }   
    
  }
   

  ngDoCheck(){
    if(this.tabsOrder){
      this.status.emit(this.tabs)
      if(JSON.parse(localStorage.getItem('loadOrders'))) {
        this.role === 'store' && this.getOrders()
      }
    }
   if(this.tabsOrderWholesale){
      this.status.emit(this.tabsWholesales)
    }
    if(this.users){
      this.status.emit(this.tabsOrdersUsersMain)
    }
    if(this.support){
      this.status.emit(this.tabsSupportMain)
    }
    if(!this.clone && this.campaign){
      this.status.emit(this.tabsCampaingMain)
    }
  }


  moveTab(type:string){

    if(type === 'file'){
      this.tabsCampaingMain = { id: 0, label: 'campaignData.uploadFile', status: 'file' }
      this.clone = !this.clone
    }

    if(type === 'contacts'){
      this.tabsCampaingMain = { id: 1, label: 'campaignData.contactos', status: 'contacts' }
      this.clone = !this.clone
    }
  }

}
