import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { ModalModule } from 'src/app/shared/modal/modal.module';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CreatePersonalComponent } from './create-personal/create-personal.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { DetailReservaComponent } from './detail-reserva/detail-reserva.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { CreateReservationComponent } from './create-reservation/create-reservation.component';


export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

@NgModule({
  declarations: [
    CreateCategoryComponent,
    CreatePersonalComponent,
    DetailReservaComponent,
    CreateReservationComponent
  ],
  exports: [
    CreateCategoryComponent,
    CreatePersonalComponent,
    DetailReservaComponent,
    CreateReservationComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ComponentsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ComponentsModule
  ],
  providers: [
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},

  ]
})
export class ModalsModule { }
