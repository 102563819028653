<div class="flex flex-wrap items-center gap-2 pl-2">
    <div class="flex gap-2 items-center w-full lg:w-max">
        <!-- desde -->
        <!-- <div class="flex items-center">
            <label class="control-label pr-1 text-sm">{{ 'date-filter.from' | translate}} </label>
            <input [(ngModel)]="from" [owlDateTime]="dt1" format="dd-mm-yyyy" #dpFromDate class="form-control mx-1 px-1 text-sm w-24 font-bold bg-transparent border-transparent dark:hover:border-dark-fondo focus:bg-white dark:focus:bg-dark-input" name="dpFromDate"
                autocomplete="off" (ngModelChange)="onDateSelection()" [owlDateTimeTrigger]="dt1">
            <div class="">
                <button [owlDateTimeTrigger]="dt1" class="" type="button">
            <span class="icon-calendar"></span>
            </button>
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            </div>
        </div> -->
        <!-- fin desde -->

        <!-- hasta -->
        <!-- <div class="flex items-center">
            <label class="control-label pr-1 text-sm">{{ 'date-filter.to' | translate}} </label>
            <input [(ngModel)]="to" [owlDateTime]="dt2" format="dd-mm-yyyy" autocomplete="off" #dpToDate [owlDateTimeTrigger]="dt2" class="form-control mx-1 px-1 text-sm w-24 font-bold bg-transparent border-transparent dark:hover:border-dark-fondo focus:bg-white dark:focus:bg-dark-input"
                (ngModelChange)="onDateSelection()" name="dpToDate">
            <div class="">
                <button [owlDateTimeTrigger]="dt2" class="" type="button">
                <span class="icon-calendar"></span>
            </button>
                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            </div>
        </div> -->

        <!-- <input type="text" matInput
        ngxDaterangepickerMd
        [(ngModel)]="selected"
        name="daterange"/>
        <button class="ngx-daterangepicker-action" type="button" (click)="open()">Open</button> -->
        <!-- fin hasta -->
    </div>
    <nav class="flex items-center gap-1 justify-between">
        <div class="relative w-6">
            <span style="top: -8px" class="icon-calendar w-6 h-6 absolute font-bold rounded focus:bg-white"></span>
            <input type="text" matInput ngxDaterangepickerMd #calendarOpen (change)="onDateSelection()" style="top: -13px;" class="bg-transparent w-full absolute inputdate" [(ngModel)]="selectedDate" name="daterange" />
        </div>
        <ng-container *ngFor="let type of types; let i = index">
            <button [id]="type.cod" class="px-1 md:px-2 h-6 text-sm font-bold rounded focus:bg-white dark:focus:bg-dark-input" (click)="selected = type; listenClick(type.cod)" [class.active]="i === selected.id">
            {{type.title | translate}}
          </button>
        </ng-container>

    </nav>
</div>