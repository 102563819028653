import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'src/app/service/alerts/alerts.service';
import { ServicesTurnosService } from 'src/app/service/servicesTurnos/services-turnos.service';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';

@Component({
  selector: 'app-create-personal',
  templateUrl: './create-personal.component.html',
  styles: [
  ]
})
export class CreatePersonalComponent implements OnInit, OnDestroy {

  staff: FormGroup
  schedules : FormGroup
  edit:boolean = false
  detail:boolean = false
  @ViewChild('modalComponent') modal:
  | ModalComponent<CreatePersonalComponent>
  | undefined;

  constructor(
    private spinner: SpinnerService,
    private fb: FormBuilder,
    private turnoService: ServicesTurnosService,
    private translate: TranslateService,
    private alerts: AlertsService
  ) {
    this.createForm()
    this.listenPersonal()
    this.listenDetail()
  }

  listenDetail(){
    this.turnoService.getDetail().subscribe(((value:boolean) => value && (this.detail = true)))
  }

  listenPersonal(){
    this.turnoService.getPersonal()
    .subscribe((personal: Object | any) => {
      if( Object.keys(personal).length > 0 ){
        console.log(personal)
        this.edit = true
        this.staff.patchValue(personal)

        this.schedules.patchValue({
          monday : JSON.parse(personal.schedule[0].monday),
          tuesday : JSON.parse(personal.schedule[0].tuesday),
          wednesday : JSON.parse(personal.schedule[0].wednesday),
          thursday : JSON.parse(personal.schedule[0].thursday),
          friday : JSON.parse(personal.schedule[0].friday),
          saturday : JSON.parse(personal.schedule[0].saturday),
          sunday : JSON.parse(personal.schedule[0].sunday)
        })

      }
    })
  }

  createForm() {
    this.staff = this.fb.group({
      id: [],
      id_store: [""],
      name: ["", Validators.required],
      lastname: ["", Validators.required],
    });

    this.schedules = this.fb.group({
      id: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    })

  }

  get name() {
    return (
      this.staff.get("name").invalid &&
      this.staff.get("name").touched
    );
  }


  get lastname() {
    return (
      this.staff.get("lastname").invalid &&
      this.staff.get("lastname").touched
    );
  }


  ngOnInit(): void {
  }

  getHours(event){
    let monday = event.mondayHours.configHorarios === null ? [] : JSON.stringify(event.mondayHours.configHorarios)
    let tuesday = event.tuesdayHours.configHorarios === null ? [] : JSON.stringify(event.tuesdayHours.configHorarios)
    let wednesday = event.wednesdayHours.configHorarios === null ? [] : JSON.stringify(event.wednesdayHours.configHorarios)
    let thursday = event.thursdayHours.configHorarios === null ? [] : JSON.stringify(event.thursdayHours.configHorarios)
    let friday = event.fridayHours.configHorarios === null ? [] : JSON.stringify(event.fridayHours.configHorarios)
    let saturday = event.saturdayHours.configHorarios === null ? [] : JSON.stringify(event.saturdayHours.configHorarios)
    let sunday = event.sundayHours.configHorarios === null ? [] : JSON.stringify(event.sundayHours.configHorarios)
    this.schedules.patchValue({
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    })
  }

  save(){
    this.spinner.show()
    let data = { ...this.staff.value, ...this.schedules.value }
    this.turnoService.postStaff(data)
    .then(res => {
      console.log(res)
      this.alerts.alertSuccess(this.translate.instant('exito'))
      this.spinner.hide()
      this.turnoService.setReload(true)
      this.close()
    })
    .catch(err => {
      console.log(err)
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
      this.spinner.hide()
    })
  }

  update(){
    this.spinner.show()
    let data = {...this.staff.value, ...this.schedules.value, id: this.staff.value.id}
    this.turnoService.updateStaff(this.staff.value.id, data)
    .then(res => {
      this.alerts.alertSuccess(this.translate.instant('exito'))
      this.close()
      this.spinner.hide()
      this.turnoService.setReload(true)
    })
    .catch(err => {
      this.spinner.hide()
      this.alerts.alertError(this.translate.instant('reservation.alerts.error'))
    })
  }

  close(){
    this.modal.close()
  }

  ngOnDestroy(){
    this.schedules.reset()
    this.staff.reset()
    this.turnoService.setPersonal({})
    this.turnoService.setReload(false)
    this.turnoService.setDetail(false)
    this.edit = false
    this.detail = false
  }

}
