import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorarioComponent } from './horario/horario.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListStoresComponent } from './list-stores/list-stores.component';
import { TranslateModule } from '@ngx-translate/core';
import { TabsComponent } from './tabs/tabs.component';
import { SlidersComponent } from './sliders/sliders.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { CardsComponent } from './cards/cards.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule as Calendar, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ListCategoriesComponent } from './list-categories/list-categories.component';

@NgModule({
  declarations: [
    HorarioComponent,
    ListStoresComponent,
    TabsComponent,
    SlidersComponent,
    CardsComponent,
    CalendarComponent,
    ListCategoriesComponent,
  ],
  exports: [
    HorarioComponent,
    ListStoresComponent,
    TabsComponent,
    SlidersComponent,
    CardsComponent,
    CalendarComponent,
    ListCategoriesComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    SwiperModule,
    NgSelectModule,
    Calendar.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ]
})
export class ComponentsModule { }
