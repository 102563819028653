<app-modal #modalComponent>


    <form *ngIf="!addServices">

        <div class="row">

            <div class="col-12">
                <input name="name" [readonly]="detail" [(ngModel)]="categoryForm.name" type="text" class="input-text" placeholder="Nombre">
            </div>

            <div class="col-12 my-5">
                <input name="description" [readonly]="detail" [(ngModel)]="categoryForm.description" type="text" class="input-text" placeholder="Descripcion">
            </div>

            <div class="col-2 mx-3 my-5 flex justify-between border rounded-md border-white p-2">
                <input name="color" [readonly]="detail" [(ngModel)]="categoryForm.color" type="color" class="">
                <i style="margin-top: 2px;" class="fas fa-fill-drip"></i>
            </div>

        </div>

    </form>

    <div *ngIf="!edit && !detail && addServices" class="border rounded-md border-white p-2">

        <div class="list-group flex flex-col">
            <li *ngFor="let service of servicesList; let i = index" class="flex">
                <input (click)="selectedService($event.target['value'], i)" type="checkbox" class="form-check-input mt-1 bg-transparent" id="{{service.id}}" [value]="service.id" aria-label="..."> <label for="{{service.id}}" class="mb-2 ml-2"> {{service.name}} </label>
            </li>
        </div>


    </div>


    <div class="mt-6 flex justify-end">
        <button (click)="close()" type="button" class="btn btn-link btn-sm mr-4"> {{'buttons.cerrar' | translate}} </button>
        <button *ngIf="!addServices" (click)=" edit ? update() : save() " type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button>
        <button *ngIf="addServices" (click)="addServicesCtg()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button>
    </div>
</app-modal>

<ng-template #toolbar>
</ng-template>