import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss']
})
export class HorarioComponent implements OnInit {
  @Input() horarios:any
  @Input() disabled : boolean = true
  @Output() event: EventEmitter<any> = new EventEmitter();
  schedules: FormGroup = this.fb.group({})
  days: any = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  };
  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,

    ) {
    this.createForm()
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    console.log("cambios", this.horarios)

      if(this.horarios.monday?.length > 0 ||
        this.horarios.tuesday?.length > 0 ||
        this.horarios.wednesday?.length > 0 ||
        this.horarios.thursday?.length > 0 ||
        this.horarios.friday?.length > 0  ||
        this.horarios.saturday?.length > 0 ||
        this.horarios.sunday?.length > 0
        ){
          this.horarios.monday && this.horarios.monday.length > 0 ? this.schedules.get('mondayHours.configHorarios').setValue(JSON.parse(this.horarios.monday)) : this.schedules.get('mondayHours.configHorarios').setValue('')
          this.horarios.tuesday && this.horarios.tuesday.length > 0 ? this.schedules.get('tuesdayHours.configHorarios').setValue(JSON.parse(this.horarios.tuesday)) : this.schedules.get('tuesdayHours.configHorarios').setValue('')
          this.horarios.wednesday && this.horarios.wednesday.length > 0 ? this.schedules.get('wednesdayHours.configHorarios').setValue(JSON.parse(this.horarios.wednesday)) : this.schedules.get('wednesdayHours.configHorarios').setValue('')
          this.horarios.thursday && this.horarios.thursday.length > 0 ? this.schedules.get('thursdayHours.configHorarios').setValue(JSON.parse(this.horarios.thursday)) : this.schedules.get('thursdayHours.configHorarios').setValue('')
          this.horarios.friday && this.horarios.friday.length > 0 ? this.schedules.get('fridayHours.configHorarios').setValue(JSON.parse(this.horarios.friday)) : this.schedules.get('fridayHours.configHorarios').setValue('')
          this.horarios.saturday && this.horarios.saturday.length > 0 ? this.schedules.get('saturdayHours.configHorarios').setValue(JSON.parse(this.horarios.saturday)) : this.schedules.get('saturdayHours.configHorarios').setValue('')
          this.horarios.sunday && this.horarios.sunday.length > 0 ? this.schedules.get('sundayHours.configHorarios').setValue(JSON.parse(this.horarios.sunday)) : this.schedules.get('sundayHours.configHorarios').setValue('')
        }
  }

  createForm(){
    this.schedules = this.fb.group({

        mondayHours: this.fb.group({
          mondayHoursOpen: "00:00",
          mondayHoursClose: "23:59",
          configHorarios: []
        }),
        tuesdayHours: this.fb.group({
          tuesdayHoursOpen: "00:00",
          tuesdayHoursClose: "23:59",
          configHorarios: []
        }),
        wednesdayHours: this.fb.group({
          wednesdayHoursOpen: "00:00",
          wednesdayHoursClose: "23:59",
          configHorarios: []
        }),
        thursdayHours: this.fb.group({
          thursdayHoursOpen: "00:00",
          thursdayHoursClose: "23:59",
          configHorarios: []
        }),
        fridayHours: this.fb.group({
          fridayHoursOpen: "00:00",
          fridayHoursClose: "23:59",
          configHorarios: []
        }),
        saturdayHours: this.fb.group({
          saturdayHoursOpen: "00:00",
          saturdayHoursClose: "23:59",
          configHorarios: []
        }),
        sundayHours: this.fb.group({
          sundayHoursOpen: "00:00",
          sundayHoursClose: "23:59",
          configHorarios: []
        })


    });
  }

  removeHorario(i: number, day) {
    switch (day) {
      case "monday":

        let monday = this.schedules.get("mondayHours").get('configHorarios').value
        monday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      case "tuesday":
        let tuesday = this.schedules.get("tuesdayHours").get('configHorarios').value
        tuesday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      case "wednesday":
        let wednesday = this.schedules.get("wednesdayHours").get('configHorarios').value
        wednesday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      case "thursday":
        let thursday = this.schedules.get("thursdayHours").get('configHorarios').value
        thursday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      case "friday":
        let friday = this.schedules.get("fridayHours").get('configHorarios').value
        friday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      case "saturday":
        let saturday = this.schedules.get("saturdayHours").get('configHorarios').value
        saturday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      case "sunday":
        let sunday = this.schedules.get("sundayHours").get('configHorarios').value
        sunday.splice(i, 1)
        this.event.emit(this.schedules.value)
        break;

      default:
        break;
    }
  }

  add(day) {


    switch (day) {
      case 'mondayHours':
        console.log("day => ", day)
        var { mondayHoursOpen, mondayHoursClose } = this.schedules.get(day).value
        console.log("open", mondayHoursOpen)
        console.log("close", mondayHoursClose)

        var send_hour = false;
        if (this.days.monday.length == 0) {
          send_hour = false;

        } else {
          this.days.monday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + mondayHoursOpen);
            var date2A = new Date('1/1/1999 ' + mondayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.monday.push({
            open: mondayHoursOpen,
            close: mondayHoursClose,
          });
        } else {

          Swal.fire(this.translate.instant('horarios.choque'), "", "error");

        }


        this.days.monday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.monday)
         this.event.emit(this.schedules.value)

        return;

      case 'tuesdayHours':

        var { tuesdayHoursOpen, tuesdayHoursClose } = this.schedules.get(day).value


        var send_hour = false;
        if (this.days.tuesday.length == 0) {
          send_hour = false;

        } else {
          this.days.tuesday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + tuesdayHoursOpen);
            var date2A = new Date('1/1/1999 ' + tuesdayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.tuesday.push({
            open: tuesdayHoursOpen,
            close: tuesdayHoursClose,
          });
        } else {

          Swal.fire(this.translate.instant('horarios.choque'), "", "error");

        }




        this.days.tuesday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.tuesday)

        this.event.emit(this.schedules.value)



        return;

      case 'wednesdayHours':

        let { wednesdayHoursOpen, wednesdayHoursClose } = this.schedules.get(day).value

        var send_hour = false;
        if (this.days.wednesday.length == 0) {
          send_hour = false;

        } else {

          this.days.wednesday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + wednesdayHoursOpen);
            var date2A = new Date('1/1/1999 ' + wednesdayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.wednesday.push({
            open: wednesdayHoursOpen,
            close: wednesdayHoursClose,
          });
        } else {

          Swal.fire(this.translate.instant('horarios.choque'), "", "error");

        }

        this.days.wednesday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.wednesday)

        this.event.emit(this.schedules.value)



        break;

      case 'thursdayHours':

        let { thursdayHoursOpen, thursdayHoursClose } = this.schedules.get(day).value



        var send_hour = false;
        if (this.days.thursday.length == 0) {
          send_hour = false;

        } else {

          this.days.thursday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + thursdayHoursOpen);
            var date2A = new Date('1/1/1999 ' + thursdayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.thursday.push({
            open: thursdayHoursOpen,
            close: thursdayHoursClose,
          });
        } else {

          Swal.fire(this.translate.instant('horarios.choque'), "", "error");

        }

        this.days.thursday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.thursday)

        this.event.emit(this.schedules.value)


        return;

      case 'fridayHours':

        let { fridayHoursOpen, fridayHoursClose } = this.schedules.get(day).value


        var send_hour = false;
        if (this.days.friday.length == 0) {
          send_hour = false;

        } else {

          this.days.friday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + fridayHoursOpen);
            var date2A = new Date('1/1/1999 ' + fridayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.friday.push({
            open: fridayHoursOpen,
            close: fridayHoursClose,
          });
        } else {

          Swal.fire(this.translate.instant('horarios.choque'), "", "error");

        }

        this.days.friday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.friday)

        this.event.emit(this.schedules.value)

        return;

      case 'saturdayHours':

        let { saturdayHoursOpen, saturdayHoursClose } = this.schedules.get(day).value

        var send_hour = false;
        if (this.days.saturday.length == 0) {
          send_hour = false;

        } else {

          this.days.saturday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + saturdayHoursOpen);
            var date2A = new Date('1/1/1999 ' + saturdayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.saturday.push({
            open: saturdayHoursOpen,
            close: saturdayHoursClose,
          });
        } else {

          Swal.fire(this.translate.instant('horarios.choque'), "", "error");

        }

        this.days.saturday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.saturday)

        this.event.emit(this.schedules.value)



        return;

      case 'sundayHours':

        let { sundayHoursOpen, sundayHoursClose } = this.schedules.get(day).value

         console.log({ sundayHoursOpen, sundayHoursClose })

        var send_hour = false;
        if (this.days.sunday.length == 0) {
          send_hour = false;

        } else {

          this.days.sunday.map(item => {

            var validar_hora1 = new Date('1/1/1999 ' + item.open);
            var validar_hora2 = new Date('1/1/1999 ' + item.close);

            var date1A = new Date('1/1/1999 ' + sundayHoursOpen);
            var date2A = new Date('1/1/1999 ' + sundayHoursClose);
            if (
              (date1A >= validar_hora1 && date1A <= validar_hora2) ||
              (date2A >= validar_hora1 && date2A <= validar_hora2) ||
              (date1A <= validar_hora1 && date2A >= validar_hora2)) {
              send_hour = true;
            }
          })
        }
        if (!send_hour) {
          this.days.sunday.push({
            open: sundayHoursOpen,
            close: sundayHoursClose,
          });
        } else {
          Swal.fire(this.translate.instant('horarios.choque'), "", "error");
        }

        this.days.sunday.sort((a,b):any => {
          if (parseInt(a.open.substr(0, 2)) < parseInt(b.open.substr(0, 2))) {
            return -1
          }
         })

        this.schedules.get(day).get('configHorarios').setValue(this.days.sunday)
        console.log(this.schedules.get(day).get('configHorarios').value)
        this.event.emit(this.schedules.value)


        return;

      default:


        break;
    }


  }

}
