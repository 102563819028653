<!-- general -->
<ngx-spinner
*ngIf="spinner === 'general' || spinner === ''"
bdColor="rgba(0, 0, 0, 0.8)"
size="medium"
color="#fff"
type="ball-triangle-path"
[fullScreen]="true"
>
<p style="font-size: 20px; color: white"> {{'actions.loading' | translate}}  </p>
</ngx-spinner>
<router-outlet></router-outlet>
<!-- general -->


<!-- pandora -->
<ngx-spinner *ngIf="spinner === 'pandora'" bdColor="rgba(0,0,0, 0.8)" color="white" [fullScreen]="true" type="null" size="small">
  <img style="width:90px" src='assets/img/loading.gif' />
</ngx-spinner>
<!-- pandora -->

<!-- purina -->
<ngx-spinner *ngIf="spinner === 'purina'" bdColor="rgba(0,0,0, 0.8)" color="white" [fullScreen]="true" type="null" size="small">
  <img style="width:90px" src='assets/img/logo-icono.png' />
</ngx-spinner>
<!-- purina -->


<!-- pepsi -->
<ngx-spinner *ngIf="spinner === 'pepsi'" bdColor="rgba(0,0,0, 0.8)" color="white" [fullScreen]="true" type="null" size="small">
  <img style="width:90px" src='assets/img/logo-impact-pepsi.png' />
</ngx-spinner>
<!-- pepsi -->
