<div class="flex flex-col justify-center items-center gap-3 mb-3">
    <div class="w-full bg-principal-claro dark:bg-dark-input flex gap-3 justify-center py-2 text-center sm:text-left">
        <h6>Fecha seleccionada {{clickedDate | date:'dd/MM/yyyy'}} </h6>
    </div>
    <div class="w-full justify-center sm:justify-between flex flex-wrap gap-3 pb-2 border-b border-gris-claro dark:border-dark-fondo mb-1">
        <button class="rounded-md text-sm sm:text-base py-1 px-2 sm:py-1.5 sm:px-4 hover:text-white hover:bg-info-hover focus:text-white focus:bg-info-hover cursor-pointer" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            {{'calendario.anterior' | translate}}
          </button>
        <button class="rounded-md text-sm sm:text-base py-1 px-2 sm:py-1.5 sm:px-4 hover:text-white hover:bg-info-hover focus:text-white focus:bg-info-hover cursor-pointer" mwlCalendarToday [(viewDate)]="viewDate">
            {{'calendario.hoy' | translate}}
            </button>
        <button class="rounded-md text-sm sm:text-base py-1 px-2 sm:py-1.5 sm:px-4 hover:text-white hover:bg-info-hover focus:text-white focus:bg-info-hover cursor-pointer" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
                {{'calendario.siguiente' | translate}}
            </button>
        <p class="text-uppercase text-base py-1.5 px-4 w-full sm:w-auto text-center sm:text-left">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</p>

    </div>
</div>

<div [ngSwitch]="view">
    <mwl-calendar-month-view (dayClicked)="getDates($event.day.date)" *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
</div>