import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const full_routes: Routes = [

  {
    path: 'clientes',
    loadChildren: () => import('../../views/clientes/clientes.module').then(m => m.ClientesModule)
  },
  {
    path: 'envios',
    loadChildren: () => import('../../views/envios/envios.module').then(m => m.EnviosModule)
  },
  {
    path: 'tiendas',
    loadChildren: () => import('../../views/tiendas/tiendas.module').then(m => m.TiendasModule)
  },
  {
    path: 'users_store',
    loadChildren: () => import('../../views/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('../../views/productos/productos.module').then(m => m.ProductosModule)
  },
  {
    path: 'categorias',
    loadChildren: () => import('../../views/categorias/categorias.module').then(m => m.CategoriasModule)
  },
  {
    path: 'toppings',
    loadChildren: () => import('../../views/toppings/toppings.module').then(m => m.ToppingsModule)
  },
  {
    path: 'etiquetas',
    loadChildren: () => import('../../views/tags/etiquetas.module').then(m => m.TagsModule)
  },
  {
    path: 'artistas',
    loadChildren: () => import('../../views/artists/artists.module').then(m => m.ArtistsModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('../../views/pdfTemplates/pdfTemplate.module').then(m => m.pdfTemplateModule)
  },
  // {
  //   path: 'turnos',
  //   loadChildren: () => import('../../views/turnos/turnos.module').then(m => m.TurnosModule)
  // },
  {
    path: 'pedidos',
    loadChildren: () => import('../../views/pedidos/pedidos.module').then(m => m.PedidosModule)
  },
  {
    path: 'compartir',
    loadChildren: () => import('../../views/compartir/compartir.module').then(m => m.CompartirModule)
  },
  {
    path: 'configuracion',
    loadChildren: () => import('../../views/configurationHome/configuration-home.module').then(m => m.ConfigurationHomeModule)
  },
  {
    path: 'wholesales',
    loadChildren: () => import('../../views/wholesales/wholesales.module').then(m => m.WholesalesModule)
  },
  {
    path: 'estadisticas',
    loadChildren: () => import('../../views/estadisticas/estadisticas.module').then(m => m.EstadisticasModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('../../views/reportes/reportes.module').then(m => m.ReportesModule)
  },
  {
    path: 'promociones',
    loadChildren: () => import('../../views/promociones/promociones.module').then(m => m.PromocionesModule)
  },
  {
    path: 'soporte',
    loadChildren: () => import('../../views/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'whatsapp',
    loadChildren: () => import('../../views/whatsapp/whatsapp.module').then(m => m.WhatsappModule)
  },
  {
    path: 'checkout-forms',
    loadChildren: () => import('../../views/checkoutForms/checkout-forms.module').then(m => m.CheckoutFormsModule)
  },
  {
    path: 'variantes',
    loadChildren: () => import('../../views/variantes/variantes.module').then(m => m.VariantesModule)
  },
  {
    path: 'campaign',
    loadChildren: () => import('../../views/campaing/campaing.module').then(m => m.CampaingModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../views/configuration/configuration.module').then(m => m.ConfigurationModule)
  },
  {
    path: 'myAccount',
    loadChildren: () => import('../../views/account/account.module').then(m => m.AccountModule)
  },

];
