import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  reservation: BehaviorSubject<any> = new BehaviorSubject({})
  category: BehaviorSubject<any> = new BehaviorSubject({})

  constructor(private httpService: HttpService) { }

  setReservation(reserva){
    this.reservation.next(reserva)
  }


  setDataCtg(ctg:object){
    this.category.next(ctg)
  }

  getDataCtg(): Observable<any>{
    return this.category
  }

  getReserva(): Observable<any>{
    return this.reservation
  }

  getEvents(startMonth:string, endMonth:string, id_store?:string){
    let params : HttpParams = new HttpParams()
    .append('start_date', startMonth)
    .append('end_date', endMonth)
    id_store && ( params = params.append('id_store', id_store) )
    return this.httpService.get(`admin/shiftreservations/list`, params)
  }

  update(id_reservation:string,data:object){
    return this.httpService.put(`admin/shiftreservations/${id_reservation}`, data)
  }

  trash(id_reservation:string, data:object){
    return this.httpService.put(`admin/shiftreservations/${id_reservation}/trash`, data)
  }

  getServices(id_store:string){
    return this.httpService.get(`services/${id_store}/list`)
  }

  getDetailCategorie(id_store:string, id_ctg:string){
    return this.httpService.get(`services/${id_store}/detail/${id_ctg}`)
  }

  listDays(id_store:string, day:string, id_staff:string, id_service:string){
    let params : HttpParams = new HttpParams()
    .append('id_service', String(id_service))
    .append('id_staff', String(id_staff))
    .append('day', String(day));

    return this.httpService.get(`shiftreservations/schedules/${id_store}`, params)
  }

  makeReservation(data){
    return this.httpService.post('shiftreservations', data)
  }

  createCustomer(id_store:string,dataCustomer:object){
    return this.httpService.post(`shiftreservations/addCustomer/${id_store}`, dataCustomer)
  }

}
