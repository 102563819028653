<div (click)="sendEvent()" class="h-full justify-stretch bg-white rounded-lg text-center flex flex-col p-4 cursor-pointer border-2 hover:border-principal focus:border-principal">
    <div class="relative" style="padding-bottom:65%;">
        <img
          *ngIf="product"
          class="absolute w-full h-full object-contain object-center rounded-lg "
          [src]="product ? (product.variants && product.variants[0].images.length > 0 ? product.variants[0].images[0].url : DEFAULT_PHOTO) : image.url " alt=""
          >
        <img *ngIf="!product" [src]="image.url" alt="">
    </div>

    <div class="mt-4 h-2/5">
        <h5 class="font-extrabold text-negro-principal uppercase text-sm"> {{product ? product.variants[0].name : 'Product name'}} </h5>
    </div>
</div>
