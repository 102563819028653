import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './layouts/content/content.component';
import { DashboardLayoutComponent } from './layouts/full/dashboard-layout/dashboard-layout.component';
import { full_routes } from './layouts/routes/full-layout.routes'
import { CONTENT_ROUTES } from './layouts/routes/content-layout.routes'
import { AuthGuardService } from './service/auth/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login', //RUTA DEFAULT EN CASO DE QUE FALLE, ANTES ESTABA LOGIN
    pathMatch: 'full',
  },
  {
    path: '', component: DashboardLayoutComponent, children: full_routes, canActivate: [AuthGuardService]
  },
  {
    path: '', component: ContentComponent, children: CONTENT_ROUTES
  },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
