<app-modal #modalComponent>

    <form class="">

        <div class="flex flex-wrap items-center justify-start max-w-xl gap-x-4 px-4 py-2 bg-gray-100 dark:bg-dark-input mb-4">
            <p class="text-xs w-1/5">Nombre y Apellido</p>
            <div class="w-3/5">
                <input readonly name="name" [value]="reservation.customer.name + ' ' + reservation.customer.lastname " type="text" class="input-text bg-transparent border-none">
            </div>
            <p class="text-xs w-1/5">Correo</p>
            <div class="w-3/5">
                <input readonly name="name" type="text" class="input-text bg-transparent border-none" [value]="reservation.customer.email">
            </div>
            <p class="text-xs w-1/5">Teléfono</p>
            <div class="w-3/5">
                <input readonly name="name" type="text" class="input-text bg-transparent border-none" [value]="reservation.customer.phone">
            </div>
            <p class="text-xs w-1/5">Titulo</p>
            <div class="w-3/5">
                <input readonly name="name" type="text" class="input-text bg-transparent border-none" [value]="reservation.title">
            </div>
            <p class="text-xs w-1/5">Staff</p>
            <div class="w-3/5">
                <input readonly name="name" type="text" class="input-text bg-transparent border-none" [value]="reservation.staff">
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-start max-w-xl gap-4">
            <label class="w-1/5">{{'calendario.empieza' | translate}}:</label>
            <div class="flex w-3/5 items-center">
                <input readonly [(ngModel)]="reservation.start" [owlDateTime]="dt1" format="dd-mm-yyyy" #dpFromDate class="form-control mr-3 px-4 py-2" name="dpFromDate" autocomplete="off" [owlDateTimeTrigger]="dt1">
                <button [owlDateTimeTrigger]="dt1" class="h-6 w-6" type="button">
                  <span class="icon-calendar"></span>
                </button>
                <owl-date-time #dt1></owl-date-time>
            </div>

            <label class="w-1/5">{{'calendario.termina' | translate}}:</label>
            <div class="flex w-3/5 items-center">
                <input readonly [(ngModel)]="reservation.end" [owlDateTime]="dt2" format="dd-mm-yyyy" #dpToDate class="form-control mr-3 px-4 py-2" name="dpToDate" autocomplete="off" [owlDateTimeTrigger]="dt2">
                <button [owlDateTimeTrigger]="dt2" class="h-6 w-6" type="button">
                    <span class="icon-calendar"></span>
                  </button>
                <owl-date-time #dt2></owl-date-time>
            </div>

            <label for="services" class="w-1/5">Servicios</label>
            <div class="w-3/5">
                <select [disabled]=" role === 'store' ? false : true " (change)="getChanges($event.target['value'])" class="input-text" name="" id="services">
                  <option *ngFor="let service of services" [value]="service.id" > {{service.name}} </option>
                </select>
            </div>

            <label for="personal" class="w-1/5">Personal</label>
            <div class=" w-3/5">
                <select [disabled]=" role === 'store' ? false : true " class="input-text" name="" id="personal">
                <option *ngFor="let personal of staff" [value]="personal.id" > {{personal.name}} {{personal.lastname}} </option>
              </select>
            </div>

        </div>

    </form>

    <div class="mt-6 mb-3 flex justify-end">
        <button (click)="close()" type="button" class="btn btn-link mr-4"> {{'buttons.cerrar' | translate}} </button>
        <button *ngIf="role === 'store'" (click)="update() " type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button>
    </div>

</app-modal>

<ng-template #toolbar>
</ng-template>
