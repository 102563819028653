import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: [ './date-filter.component.scss' ]
})
export class DateFilterComponent implements OnInit {

  types: any[] = [
    { id: 0, title: 'date-filter.day', class: '', method: '', cod: 'day', fn: 'setDayInCalendar' },
    { id: 1, title: 'date-filter.week', class: '', method: '', cod: 'week', fn: 'setWeekInCalendar' },
    { id: 2, title: 'date-filter.month', class: '', method: '', cod: 'month', fn: 'setMonthInCalendar' },
    { id: 3, title: 'date-filter.3month', class: '', method: '', cod: '3month', fn: 'set3MonthInCalendar' },
    { id: 4, title: 'date-filter.year', class: '', method: '', cod: 'year', fn: 'setYearInCalendar' },
    { id: 5, title: 'date-filter.year_to_day', class: '', method: '', cod: 'ytd', fn: '' },
  ]
  selected: any = {id: 2, title: 'date-filter.month', class: '', method: ''}
  fromDate:any = {}
  toDate:any = {}
  from:string = ''
  to:string = ''
  active:any
  role:string = JSON.parse(localStorage.getItem('role'))
  @Output() filterDates: EventEmitter<any> = new EventEmitter();
  @ViewChild(DaterangepickerDirective, {static: true}) picker: DaterangepickerDirective;
  @ViewChild('calendarOpen') public calendarOpen: ElementRef<HTMLElement>;
  selectedDate: {startDate: moment.Moment, endDate: moment.Moment};
  constructor() {
   
  }
  // all y log afuera
  // no hay logo

  open() {
    this.picker.open();
  }

  listenClick(id:string){
    if( id === 'day' ) this.setDayInCalendar()
    if( id === 'week' ) this.setWeekInCalendar()
    if( id === 'month' ) this.setMonthInCalendar()
    if( id === '3month' ) this.set3MonthInCalendar()
    if( id === 'year' ) this.setYearInCalendar()
    if( id === 'ytd' ) this.setYTD()
  }

  ngOnInit(): void {
    this.setMonthInCalendar()
    // this.role === 'store' && this.listenClick('month')
    // let dateToTransform = moment()
    // this.fromDate = this.transformDate('month', dateToTransform).fromDate
    // this.toDate = this.transformDate('month', dateToTransform).toDate
    // let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    // fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    // let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    // toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    // this.from = fromDateToString
    // this.to = toDateToString
  }


  setYTD(){
    let dateToTransform = moment()
    this.fromDate = this.transformDate('ytd', dateToTransform).fromDate
    this.toDate = this.transformDate('ytd', dateToTransform).toDate
    let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    this.from = fromDateToString
    this.to = toDateToString
    this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
  }

  setMonthInCalendar(){
    let dateToTransform = moment()
    this.fromDate = this.transformDate('month', dateToTransform).fromDate
    this.toDate = this.transformDate('month', dateToTransform).toDate
    let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    this.from = fromDateToString
    this.to = toDateToString

    this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
  }

  set3MonthInCalendar(){
    let dateToTransform = moment()
    this.fromDate = this.transformDate('3month', dateToTransform).fromDate
    this.toDate = this.transformDate('3month', dateToTransform).toDate
    let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    this.from = fromDateToString
    this.to = toDateToString
    this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
  }

  setDayInCalendar(){
    let dateToTransform = moment()
    this.fromDate = this.transformDate('day', dateToTransform).fromDate
    this.toDate = this.transformDate('day', dateToTransform).toDate
    let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    this.from = fromDateToString
    this.to = toDateToString
    this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
  }

  setWeekInCalendar(){
    let dateToTransform = moment()
    this.fromDate = this.transformDate('week', dateToTransform).fromDate
    this.toDate = this.transformDate('week', dateToTransform).toDate
    let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    this.from = fromDateToString
    this.to = toDateToString
    this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
  }

  setYearInCalendar(){
    let dateToTransform = moment()
    this.fromDate = this.transformDate('year', dateToTransform).fromDate
    this.toDate = this.transformDate('year', dateToTransform).toDate
    let fromDateToString:any = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`
    fromDateToString = moment(fromDateToString).format('YYYY-MM-DD')
    let toDateToString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    toDateToString = moment(toDateToString).format('YYYY-MM-DD')
    this.from = fromDateToString
    this.to = toDateToString
    this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
  }

  onDateSelection(){
    if(this.selectedDate && this.selectedDate.startDate && this.selectedDate.endDate){
      let fromDateToString = this.selectedDate.startDate.format('YYYY-MM-DD')
      let toDateToString = this.selectedDate.endDate.format('YYYY-MM-DD')
      this.filterDates.emit({startDate: fromDateToString, endDate: toDateToString})
      this.selectedDate = null
    }
  }


  transformDate(type:string = 'week', data){
    let to:any = moment().format('DD/MM/YYYY').split('/')
    let from = data

    let fromDate:any = {
      day: 0,
      month: 0,
      year: 0
    }

    let toDate:any = {
      day: 0,
      month: 0,
      year: 0
    }

    switch (type) {
      case 'week':
        from = data.subtract(7, 'd')
        var newData = from.format('DD/MM/YYYY')
        var transform = newData.split('/')
        fromDate = {
          day: Number(transform[0]),
          month: Number(transform[1]),
          year: Number(transform[2])
        }

        toDate = {
          day: Number(to[0]),
          month: Number(to[1]),
          year: Number(to[2])
        }

        return {fromDate, toDate}

      case 'month':

        from = data.subtract(1, 'months')
        var newData = data.format('DD/MM/YYYY')
        var transform = newData.split('/')

        fromDate = {
          day: Number(transform[0]),
          month: Number(transform[1]),
          year: Number(transform[2])
        }

        toDate = {
          day: Number(to[0]),
          month: Number(to[1]),
          year: Number(to[2])
        }

        return { fromDate, toDate }

      case '3month':

        from = data.subtract(3, 'months')
        var newData = data.format('DD/MM/YYYY')
        var transform = newData.split('/')

        fromDate = {
          day: Number(transform[0]),
          month: Number(transform[1]),
          year: Number(transform[2])
        }

        toDate = {
          day: Number(to[0]),
          month: Number(to[1]),
          year: Number(to[2])
        }

        return { fromDate, toDate }

      case 'day':
        from = data.subtract(1, 'day')
        var newData = data.format('DD/MM/YYYY')
        var transform = newData.split('/')
        fromDate = {
          day: Number(transform[0]),
          month: Number(transform[1]),
          year: Number(transform[2])
        }

        toDate = {
          day: Number(to[0]),
          month: Number(to[1]),
          year: Number(to[2])
        }

        return {fromDate, toDate}

        case 'year':
          from = data.subtract(1, 'year')
          var newData = from.format('DD/MM/YYYY')
          var transform = newData.split('/')
          fromDate = {
            day: Number(transform[0]),
            month: Number(transform[1]),
            year: Number(transform[2])
          }

          toDate = {
            day: Number(to[0]),
            month: Number(to[1]),
            year: Number(to[2])
          }

          return {fromDate, toDate}

        case 'ytd':
          from = moment().startOf('year')
          var newData = from.format('DD/MM/YYYY')
          var transform = newData.split('/')
          fromDate = {
            day: Number(transform[0]),
            month: Number(transform[1]),
            year: Number(transform[2])
          }

          toDate = {
            day: Number(to[0]),
            month: Number(to[1]),
            year: Number(to[2])
          }
          return {fromDate, toDate}
      default:
        break;
    }
  }

}
